import * as React from "react"
import { Layout } from "../components/layout"
import { Card, Container } from "react-bootstrap"
import { Seo } from "../components/seo"

export default function PrivacyPage() {
  return (
    <Layout>
      <Container className="pt-7 pb-4">
        <h1 className="display-2 fw-bold text-center">Privacyverklaring</h1>
        <Card>
          <Card.Body>
            <Card.Text>
              <p className="text-center">
              Versie 20-05-2022<br/>
              Zelfgemaakt Lekkers<br/>
              Nieuwstraat 77, 3511 Hasselt<br/>
              heidi@zelfgemaaktlekkers.be<br/>
              BTW BE 0861 840 842<br/>
              </p>
              <h4 className="fw-bold">PERSOONSGEGEVENS DIE WORDEN VERWERKT</h4>
              <p>
                Zelfgemaakt kan waar nodig persoonsgegevens over u verwerken, doordat u gebruik maakt van de diensten
                van Zelfgemaakt en/of omdat u deze zelf bij het invullen van het contactformulier op de website aan
                Zelfgemaakt verstrekt.<br/>
                Zelfgemaakt kan de volgende persoonsgegevens verwerken:
                <ul>
                  <li>Uw voor- en achternaam</li>
                  <li>Uw e-mailadres</li>
                </ul>
              </p>
              <h4 className="fw-bold">WAAROM ZELFGEMAAKT GEGEVENS NODIG HEEFT</h4>
              <p>
                Om u een goed werkende website te leveren en de beste dienst te bieden, hebben we een aantal
                persoonlijke gegevens van u nodig. Deze persoonsgegevens gebruiken we alleen voor het doel waarvoor u ze
                aan ons verstrekt. Zelfgemaakt verwerkt uw persoonsgegevens om contact met u op te kunnen nemen indien
                nodig, en/of om u schriftelijk (per e-mail en/of per post) te kunnen benaderen indien u telefonisch
                onverhoopt niet bereikt kunt worden.
              </p>
              <h4 className="fw-bold">HOE LANG ZELFGEMAAKT GEGEVENS BEWAART</h4>
              <p>
                Zelfgemaakt bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren,
                waarvoor uw gegevens worden verzameld. Uw gegevens worden niet langer dan een jaar bewaard indien er
                geen overeenkomst met u tot stand komt.
              </p>
              <h4 className="fw-bold">DELEN MET ANDEREN</h4>
              <p>
                Zelfgemaakt verstrekt uw persoonsgegevens alléén aan derden indien dit nodig is voor de uitvoering van
                een overeenkomst met u, of om te voldoen aan een wettelijke verplichting.
              </p>
              <h4 className="fw-bold">SOCIAL MEDIA</h4>
              <p>
                Op onze website zijn buttons opgenomen om webpagina’s te promoten (“liken”) of delen (“tweeten”) op
                sociale netwerken als Facebook en Twitter. Deze buttons werken door middel van stukjes code, die van
                respectievelijk Facebook of Twitter zelf afkomstig zijn. Door middel van deze code worden cookies
                geplaatst. Wij hebben daar geen invloed op. Lees de privacyverklaring van Facebook of Twitter na (welke
                regelmatig kunnen wijzigen) om te weten wat zij met je (persoons)gegevens doen die ze via deze cookies
                verwerken. De informatie die ze verzamelen wordt zo veel mogelijk geanonimiseerd. De informatie wordt
                overgebracht naar en door Twitter, Facebook, Google+ en LinkedIn, opgeslagen op servers in de Verenigde
                Staten. LinkedIn, Twitter, Facebook en Google+ stellen zich te houden aan de Privacy Shield principes en
                zijn aangesloten bij het Privacy Shield-programma van het Amerikaanse Ministerie van Handel. Dit houdt
                in dat er sprake is van een passend beschermingsniveau voor de verwerking van eventuele
                persoonsgegevens.
              </p>
              <h4 className="fw-bold">GEGEVENS INZIEN, AANPASSEN OF VERWIJDEREN</h4>
              <p>
                U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. U kunt een verzoek
                tot inzage, correctie of verwijdering sturen naar heidi@zelfgemaaktlekkers.be. Zelfgemaakt zal zo snel
                mogelijk, maar binnen vier weken, op uw verzoek reageren.
              </p>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Privacy"
  />
)
